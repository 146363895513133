import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { endClass, startClass } from '../../../services/apiServiceAidt';
import {
  invalidateToken,
  removeUrlParameterAndReloadAndMoveLogoutPage,
} from '../../../services/ssoService';
import {
  setAccessToken,
  setUserId,
  setUserStatus,
  setUserType,
} from '../../../store/slices/userSlice';
import { RootState } from '../../../types';
import SuccessModal from '../Common/SuccessModal';

// import { invalidateToken } from '../../../services/ssoService';

interface SettingDrawerProps {
  item: {
    name: string;
    value: string;
  };
}

const settings = [
  {
    id: 'SETTING-3',
    items: [
      {
        id: 'SETTING-1-1',
        title: 'language.ko',
        value: 'ko',
      },
      {
        id: 'SETTING-1-2',
        title: 'language.en',
        value: 'en',
      },
    ],
    title: 'option.multilingual_feature',
  },
  {
    id: 'SETTING-4',
    items: [
      {
        id: 'SETTING-4-1',
        title: 'option.textsize_feature_base',
        value: 'base',
      },
      {
        id: 'SETTING-4-2',
        title: 'option.textsize_feature_lg',
        value: 'lg',
      },
      {
        id: 'SETTING-4-3',
        title: 'option.textsize_feature_xl',
        value: 'xl',
      },
    ],
    title: 'option.textsize_feature',
  },
  {
    id: 'SETTING-5',
    items: [
      {
        id: 'SETTING-5-1',
        title: '',
        value: `https://policy.${process.env.REACT_APP_MY_DOMAIN}`,
      },
    ],
    title: 'option.privacy_policy',
  },
  {
    id: 'SETTING-6',
    items: [
      {
        id: 'SETTING-6-1',
        title: '',
        value: `https://policy.${process.env.REACT_APP_MY_DOMAIN}/policy-1.html`,
      },
    ],
    title: 'option.youth_protection_policy',
  },
];

export default function SettingDrawer({ item }: SettingDrawerProps) {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [anchorEls, setAnchorEls] = React.useState<
    Record<string, HTMLElement | null>
  >({});
  const [popupState, setPopupState] = React.useState('ready');

  const access_token = useSelector(
    (state: RootState) => state.user.access_token,
  );
  const isStudent =
    useSelector((state: RootState) => state.user.user_type) === 'S';
  const userInfo = isStudent
    ? useSelector((state: RootState) => state.user.studentInfo)
    : useSelector((state: RootState) => state.user.teacherInfo);
  const currentUser = useSelector((state: RootState) => state.user);
  const user_id = useSelector((state: RootState) => state.user.user_id);
  const userType = useSelector((state: RootState) => state.user.user_type);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  const handleClick = (item: any) => (event: React.MouseEvent<HTMLElement>) => {
    if (item.id === 'SETTING-5' || item.id === 'SETTING-6') {
      window.open(item.items[0].value, '_blank');
      return;
    }
    setAnchorEls(prev => ({ ...prev, [item.id]: event.currentTarget }));
  };

  const handleClose = (id: string) => () => {
    setAnchorEls(prev => ({ ...prev, [id]: null }));
  };

  const handleLogout = () => {
    invalidateToken();
  };

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
  };

  const handleLanguageChange = (language: string, id: string) => {
    i18n.changeLanguage(language);
    handleClose(id)();
  };
  const handleUserTypeChange = (type: 'S' | 'T') => {
    dispatch(setUserType(type));
  };

  const deleteAccessibilityClassnames = () => {
    document.documentElement.classList.remove('accessibility-lg');
    document.documentElement.classList.remove('accessibility-xl');
  };

  const handleGlobalFontSizeChange = (fontSize: string) => {
    deleteAccessibilityClassnames();
    if (fontSize === 'lg') {
      //add html tag to class name 'accessibility-lg'
      document.documentElement.classList.add('accessibility-lg');
    } else if (fontSize === 'xl') {
      document.documentElement.classList.add('accessibility-xl');
    }
  };

  const handleEveryMenuItemClick = (id: string, value: string) => {
    if (id === 'SETTING-1') {
      handleUserTypeChange(value as 'S' | 'T');
    } else if (id === 'SETTING-3') {
      handleLanguageChange(value, id);
    } else if (id === 'SETTING-4') {
      handleGlobalFontSizeChange(value);
    }
  };

  const DrawerList = items => (
    <Box>
      <List sx={{ position: 'relative', py: 0, width: '100%' }}>
        {items.map(item => (
          <React.Fragment key={item?.id}>
            <ListItemButton
              alignItems="center"
              aria-controls={anchorEls[item.id] ? 'basic-menu' : undefined}
              aria-expanded={anchorEls[item.id] ? 'true' : undefined}
              aria-haspopup="true"
              disabled={!item.items}
              onClick={handleClick(item)}
              sx={{ gap: '.75rem' }}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{ fontSize: '.875rem', fontWeight: 500 }}
                    variant="caption"
                  >
                    {t(item.title)}
                  </Typography>
                }
              />
              <IconButton
                size="small"
                sx={{
                  '& img': {
                    transform: anchorEls[item.id]
                      ? 'rotate(90deg)'
                      : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  },
                  display:
                    item.id === 'SETTING-5' || item.id === 'SETTING-6'
                      ? 'none'
                      : 'block',
                  height: '1.5rem',
                  width: '1.5rem',
                }}
              >
                <img alt="Chevron Right" src="/icons/chevron-right.svg" />
              </IconButton>
            </ListItemButton>
            <Menu
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorEl={anchorEls[item.id]}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              id="basic-menu"
              onClose={handleClose(item.id)}
              open={Boolean(anchorEls[item.id])}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: 0,
                  boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
                  minWidth: '16.875rem',
                },
              }}
            >
              {item?.items?.map(subItem => (
                <MenuItem
                  key={subItem?.id}
                  onClick={() => {
                    handleEveryMenuItemClick(item.id, subItem.value);
                  }}
                  sx={{
                    alignItems: 'center',
                    color: '#555',
                    display: 'flex',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    gap: '.5rem',
                    p: '0.625rem 1.25rem',
                  }}
                >
                  {subItem.value && item.id === 'SETTING-3' && (
                    <img
                      alt={subItem.value}
                      src={`/images/flag/${subItem.value}.svg`}
                    />
                  )}
                  {t(subItem.title)}
                  {subItem.value === i18n.language && (
                    <img
                      alt="check"
                      className="check"
                      src="/icons/check-s.svg"
                    />
                  )}
                </MenuItem>
              ))}
              {/* <Divider sx={{ borderColor: '#DCDCDC' }} />
              <Stack direction="row" justifyContent="end" px=".625rem">
                <Button sx={{ color: '#7292D7' }} variant="text">
                  {t('option.add_language')} +
                </Button>
              </Stack> */}
            </Menu>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {popupState !== 'ready' && (
        <SuccessModal
          onClose={() => {
            setPopupState('ready');
          }}
          text={
            popupState === 'startedClass'
              ? '수업이 시작되었습니다.'
              : '수업이 종료되었습니다.'
          }
        />
      )}
      <Box
        onClick={toggleDrawer(true)}
        sx={{
          alignItems: 'center',
          color: 'inherit',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '.75rem',
          justifyContent: 'center',
          margin: '0 7px',
          textDecoration: 'none',
        }}
      >
        <img
          alt={item.name}
          height={40}
          src={`/icons/${item.name}.svg`}
          width={40}
        />
        {t(`nav.${item.name}`)}
      </Box>
      <Drawer
        PaperProps={{
          sx: { display: 'flex', flexDirection: 'column', width: '24.75rem' },
        }}
        anchor="right"
        onClose={toggleDrawer(false)}
        open={open}
      >
        <Stack
          direction="row"
          gap=".9375rem"
          sx={{
            px: '1.125rem',
            py: '1.25rem',
          }}
        >
          <Avatar
            alt={userInfo?.user_name as string}
            src={`/images/profile/female_${
              isStudent ? 'student' : 'teacher'
            }.svg`}
            sx={{ height: 46, width: 46 }}
          />
          <Stack direction="column" justifyContent="center">
            <Typography
              color="black"
              sx={{ fontSize: '.875rem', fontWeight: 500 }}
            >
              {userInfo?.user_name}
            </Typography>
            {/*  TODO : 교사 학생 현재상태 임시 작성  */}
            {userType === 'T' ? t('userType.teacher') : t('userType.student')}
          </Stack>
        </Stack>

        {/*<Box*/}
        {/*  sx={{*/}
        {/*    height: '3.3125rem',*/}
        {/*    display: 'flex',*/}
        {/*    alignItems: 'center',*/}
        {/*    backgroundColor: '#E0FAEC',*/}
        {/*    paddingX: '1.25rem',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    variant="subtitle1"*/}
        {/*    sx={{*/}
        {/*      fontSize: '.9375rem',*/}
        {/*      fontWeight: 'medium',*/}
        {/*      color: '#555',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t('option.account')}*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        {/*{DrawerList(authSettings)}*/}
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: '#E0FAEC',
            display: 'flex',
            height: '3.3125rem',
            paddingX: '1.25rem',
          }}
        >
          <Typography
            sx={{
              color: '#555',
              fontSize: '.9375rem',
              fontWeight: 'medium',
            }}
            variant="subtitle1"
          >
            {t('option.feature_support')}
          </Typography>
        </Box>
        {DrawerList(settings)}
        {/* logout button */}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: '1.25rem',
          }}
        >
          <Stack
            direction={'column'}
            spacing={1}
            sx={{
              height: 'calc(100vh - 200px)',
              position: 'relative',
              width: '100%',
            }}
          >
            {userType === 'S' && (
              <>
                <Button
                  aria-label="수업시작"
                  color="primary"
                  onClick={async () => {
                    setPopupState('ready');
                    await startClass(
                      access_token.token,
                      access_token.access_id,
                      user_id,
                      partnerId,
                    );
                    setPopupState('startedClass');
                  }}
                  sx={{ borderRadius: '3px', padding: '5px', width: '100%' }}
                  variant="contained"
                >
                  수업시작
                </Button>

                <Button
                  aria-label="수업종료"
                  color="primary"
                  onClick={async () => {
                    setPopupState('ready');
                    await endClass(
                      access_token.token,
                      access_token.access_id,
                      user_id,
                      partnerId,
                    );
                    setPopupState('endedClass');
                  }}
                  sx={{ borderRadius: '3px', padding: '5px', width: '100%' }}
                  variant="contained"
                >
                  수업종료
                </Button>
              </>
            )}
            <div className="h-[3rem]"></div>
            <Button
              color="primary"
              onClick={() => {
                dispatch(
                  setAccessToken({
                    access_id: '',
                    token: '',
                  }),
                );
                dispatch(setUserId(''));
                dispatch(setUserType('S'));
                dispatch(setUserStatus('E'));

                localStorage.removeItem('access_token');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user_type');
                localStorage.removeItem('user_status');
                handleLogout();
                removeUrlParameterAndReloadAndMoveLogoutPage();
              }}
              sx={{
                borderRadius: '3px',
                padding: '5px',
              }}
              variant="contained"
            >
              {t('option.logout')}
            </Button>
            <div className="text-sm text-slate-500 py-6">
              <div>Ver. 1.1</div>
            </div>
            {process.env.REACT_APP_NODE_ENV === 'development' && (
              <>
                <div className="text-sm font-black">디버깅</div>
                <Button
                  onClick={() => {
                    handleUserTypeChange(userType === 'S' ? 'T' : 'S');
                  }}
                  sx={{
                    borderRadius: '3px',
                    padding: '5px',
                  }}
                  variant="contained"
                >
                  교사/학생 즉시 전환
                </Button>
                <div className="text-sm font-black">유저정보</div>
                <div className="max-h-40 border border-slate-500 p-1 text-xs w-full overflow-y-auto">
                  {JSON.stringify(currentUser, null, 2)}
                </div>
              </>
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
